@tailwind base;
@tailwind components;
@tailwind utilities;

 /* index.css */
/* @import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities'; */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: "Omnes Regular";   /*Can be any text*/
  src: local("Omnes"),
    url("./assests/fonts/omnes-2-cdnfonts/Omnes\ Regular.woff2") format("truetype");
    font-display: swap;
}
@font-face {
  font-family: "Omnes Medium";   /*Can be any text*/
  src: local("Omnes"),
    url("./assests/fonts/omnes-2-cdnfonts/Omnes\ Medium.woff2") format("truetype");
    font-display: swap;
}
@font-face { 
  font-family: "SF Pro Display"; 
  font-style: normal; 
  font-weight: 100; 
  src: url("./assests/fonts/SF-Pro-Text/SF-Pro.woff2") format("opentype");
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro Display Light';
  font-style: normal;
  font-weight: 300;
  src: url("./assests/fonts/SF-Pro-Text/SF-Pro-Display-Light.woff2") format("opentype");
  font-display: swap;
}
 @font-face {
  font-family: 'SF Pro Display Medium';
  font-style: normal;
  font-weight: 500;
  src: url("./assests/fonts/SF-Pro-Text/SF-Pro-Display-Medium.woff2") format("opentype");
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro Display Semibold';
  font-style: normal;
  font-weight: 600;
  src: url("./assests/fonts/SF-Pro-Text/SF-Pro-Text-Semibold.woff2") format("opentype");
  font-display: swap;
}


